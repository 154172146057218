
.cabecalho{
    border-style: solid;
    border-width: 1px;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
}
.cabecalho div input{
        margin-bottom: 15px;
}
@media(min-width: 800px){
    .cabecalho{
        border-style: solid;
        border-width: 1px;
        padding: 5px;
        margin-bottom: 10px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
    }
    .cabecalho div input{
        margin-bottom: 0;
}
}